<template>
    <div>
        <withdraw-on-temp></withdraw-on-temp>
    </div>
</template>

<script>
import WithdrawOnTemp from "../../../../components/withdrawal/WithdrawOnTemp.vue";
export default {
    components: { WithdrawOnTemp },
};
</script>

<style></style>
